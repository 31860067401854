import React, { Component } from 'react';

class Exploring extends Component {

	render() {
	    return (<div className="Exploring">
	      	Exploring
	    </div>);
	}
}

export default Exploring;
