import React, { Component } from 'react';

class About extends Component {

	render() {
	    return (<div className="About">
	      	About
	    </div>);
	}
}

export default About;
